<template>
    <div class="home-page">
		<Head />
        <div class="index-banner">
			<swiper class="swiper" :options="swiperOptionTop" ref="swiperTop">
				<swiper-slide v-for="(item,index) in slide.length" :key='index'>
					<!-- <img src="../assets/images/index_banner.jpg" alt=""> -->
					<img :src="slide[index]" alt="">
				</swiper-slide>
			</swiper>
		</div>
        <div class="finance-morning-tea-detail-page">
        <!-- <div class="body-nav-btn" @click="handleNavToggle"></div> -->
            <div class="detail-body">
                <div class="body-side-bar" v-show="navShow">
                <SideBar
                    ref="sideBarRef"
                    :data="data"
                    v-model="activeKey"
                    @change="handleChange"
                />
                </div>
                <div class="body-content">
                <Content :data="data1" ref="detailListRef" />
                </div>
            </div>
        </div>
        <Foot />
    </div>
  </template>
   
  <script>
  import Content from '@/components/content.vue';
  import SideBar from '@/components/SideBar.vue';
  import Head from '@/components/Head.vue';
  import Foot from '@/components/Foot.vue' ;
  export default {
    name: 'financeMorningDetail',
    components: { SideBar, Content ,Head, Foot},
    data() {
      return {
        data: [
          {
            id: 0,
            title: '​Introduction',
            content: `
              GreenSound is committed to being a good steward of your personal information, 
              handling it in a responsible manner, and securing it with industry-standard administrative, technical, 
              and physical safeguards. When it comes to using your information, our principles are simple. 
              We will be transparent about what information we collect and process. Where possible, 
              we endeavor to give you control over the information you provide to us. 
              We will include any updates to our privacy statements on this privacy portal, including how we use and process your information. If you have any questions, 
              please review our Privacy Statement or contact us at management@greensound.net.cn.`
          },
          {
            id: 1,
            title: 'Privacy Statement',
            content: `Last Updated: December 1, 2022 

GreenSouGreenSound International (“GreenSound" or “the Company") values your privacy and is committed to protecting your Personal Information. This Privacy Statement explains how we process, store, share, and protect the Personal Information that you provide when you use our products and solutions, our website (the “Site"), or otherwise interact with the Company. For the purposes of this Privacy Statement, “Personal Information" means information that relates to you as an individually identifiable person, such as your name, e-mail address, and mobile number.
Alternative formats of this Privacy Statement are available to individuals with a disability. Please contact management@GreenSound .net.cn for assistance.
This Privacy Statement addresses the following topics:
Personal Information We Collect
Personal Information We Collect From You
Personal Information We Collect Through Technology On Our Site
Personal Information We Receive From Third Parties
Purposes For Which We Use & Collect Your Personal Information
When We Share Your Personal Information
How We Protect Your Personal Information
Region-Specific Information
All Locations Outside of The United States
European Economic Area, the United Kingdom and Switzerland
Brazil
People's Republic of China
California
Changes To This Privacy Statement
Personal Information We Collect
Personal Information We Collect From You
GreenSound  may collect Personal Information from you when you use this Site, use our products or automotive solutions, register your GreenSound   product, or directly interact with the Company at a trade show or conference, or when you contact the Company (collectively referred to in this Privacy Statement as the “Services"). The Personal Information we may collect is dependent on the Services you use. The categories of Personal Information that we collect may include the following:
Contact Information & Identifiers: Including your name; address; email address; telephone number; unique online identifier, Internet Protocol address (“IP address"); account name; and date of birth.
Login Information: Including the username and password you use to access features on GreenSound 's website and other Services.
Payment Information: Including your payment method; bank account number, debit card number, credit card number, CVV / CID, expiration date; billing address.
Location Information:  Including geolocation information collected when you use certain Services.
Product & Service Usage Information:  Including product name, serial number, place of purchase, product usage information, color, and version; product reviews and warranty information.
Purchase History Information: Including the names of the products purchased, the place(s) of purchase, and the price paid for the product(s).
Internet or Other Electronic Network Activity Information: Including pages visited on GreenSound 's websites and links clicked; IP address; location information; language preference and interactions with ads.
Biometric Information: Including voice recordings, from which an identifier template, such as a voiceprint which can be extracted, sleep and health data that contain identifying information, and fingerprint data.
Sensory Information: Including audio, visual, thermal or similar information that can be linked or associated with a particular consumer or household.
Other Information You Provide: Including Personal Information that relates to your use of our Services, such as the date / time you used our Services, the length of time for which you used our Services, or the reason for which you used our Services.
Personal Information We Collect Through Technology On Our Site
We collect information through technology to enhance our ability to serve you. When you access and use the Site, GreenSound and, in some cases, our third-party service providers collect information about how you interact with the Site. 
Please see the link below for more information: Cookie Policy 
Personal Information We Receive From Third Parties
Depending on the services you use, GreenSound may also collect information about you from a third party, including:  
Service Providers: Including our website payment processor, companies that we work with to service our products, and the organizers of our conferences and trade show events.
Vendors: Including companies that sell our products.
Original Equipment Manufacturers (“OEMs"): Including companies that use our products within their vehicles. 
Purposes For Which We Collect & Use Your Personal Information
GreenSound may use your Personal Information for thefollowing purposes:
Providing our Services
Improving our Services
Communicating with you
Verifying your identity
Registering users for GreenSound  promotions and events
Responding to technical support questions and concerns
Contacting users for marketing, advertising, and sales purposes
Responding to questions and feedback
Conducting market research and analysis
Conducting internal research for technological development
Continuously evaluating and improving the online user experience
Network and information security
Fraud prevention
To permit access to our facilities
Reporting suspected criminal acts
Compliance with the law or to protect the rights, property, or safety of GreenSound , our users, or others
When We Share Your Personal Information
We do not sell or rent your Personal Information to third parties. The following are some of the ways we share your Personal Information:  
Third-Party Service Providers: We will share your Personal Information with third-party service providers under contract with GreenSound  to help us provide services to you. The following are some examples of the types of third-party service providers with which we share your Personal Information and our purpose for doing so:
oData Analytics: We retain third-party service providers to help us perform data analytics regarding your interactions with the Site. For example, Google Analytics may track what pages users visit on the Site and how long they stay there to determine how users use the Site.
oLegal Advice: We may disclose your Personal Information to an attorney in the process of obtaining legal advice.
oThird-party Processors: We use third-party order and payment processors when you purchase products from our Site or contact our local service providers in certain countries. We also use third-party service providers to organize GreenSound  conferences and trade shows.
oAdvertising Platforms: We may disclose the fact that you visited the Site to advertising networks so that they can show you GreenSound  advertisements on other websites and platforms. These services can help us tailor advertising that we think may be of interest to you based on your use of the Site and to otherwise collect and use data about your use of the Site.
We require these third-party service providers, by written agreement, to provide safeguards for your Personal Information similar to the ones that we provide.
Required Disclosures: We may be required to share Personal Information in a court proceeding, in response to a court order, subpoena, civil discovery request, other legal process, or as otherwise required by law.
Legal Compliance and Protections: We may disclose your Personal Information when we believe disclosure is necessary to comply with the law or to protect the rights, property, or safety of GreenSound , our users, or others. This includes exchanging Personal Information with other companies and organizations for fraud protection and credit risk reduction.
Corporate Transactions: We reserve the right to disclose and transfer your Personal Information:
oIn connection with a corporate merger, consolidation, bankruptcy, the sale of substantially all of our membership interests and/or assets or other corporate change, including to any prospective purchasers.
oTo a subsequent owner, co-owner, or operator of the Site or successor database.
How We Protect Your Personal Information
The security and confidentiality of your Personal Information is important to us. We have technical, administrative, and physical security measures in place to protect your Personal Information from unauthorized access or disclosure and improper use.
For example, we use Transport Security Layer (TSL) encryption to protect the data collection forms on our Site. In addition, we restrict access to your Personal Information. Only employees who need the Personal Information to perform a specific job (for example, a customer service representative) are granted access to Personal Information. Employees with access to Personal Information are kept up-to-date on our security and privacy practices.
It is important for you to protect against unauthorized access to your password and to your computer. Be sure to close your browser after you have completed your visit to the Site. Please note that despite our reasonable efforts, no security measure is ever perfect or impenetrable, so we cannot guarantee the security of your Personal Information.
Region-Specific Information
All locations Outside Of The United States
The Personal Information that GreenSound  collects through this Site is downloaded to a server maintained by GreenSound  in the United States. GreenSound is located at 400 Atlantic Street, 15th Floor, Stamford, CT 06901 in the United States.
Because GreenSound  is a global company with locations all over the world, the location in which GreenSound  stores the Personal Information that the Company collects from you outside of this Site—for example, when you contact us or directly interact with GreenSound  at a trade show or conference—will depend on your country of residence. For more information please contact management@GreenSound .net.cn.
Cross-Border Data Transfers: In order to provide our Services, your Personal Information may need to be accessed by our entities across the world, including in the United States. Before transferring your Personal Information to any of our entities across the world, GreenSound will ensure all required transfer mechanisms are in place to ensure an adequate level of protection for the transfer of your Personal Information.
Legal Bases For Processing: GreenSound processes your Personal Information with your consent and as required by law. Where applicable, GreenSound processes your Personal Information as necessary for the performance of any contracts with you; for example, when processing your orders and payments, or providing you with our Services, and to take steps, at your request, before entering into a contract with you. GreenSound also processes Personal Information as necessary for its legitimate interests as follows:
oMarketing and advertising: Unless you opt out as described below, we use your Personal Information regarding products and services you have ordered, or in which you have otherwise demonstrated an interest, as necessary to provide you information about the products and services that we think might interest you in accordance with applicable law.
oNetwork and information security, fraud prevention, and reporting suspected criminal acts: In the event of fraud, a security incident, or a suspected criminal act, we would examine Personal Information that appeared to be linked to the incident as necessary to determine what happened, remediate, report to the authorities, and prevent a recurrence.
Automated Decision Making: GreenSound sometimes uses Personal Information submitted through the Site for automated decision-making. For example, GreenSound may display advertisements and send emails to you containing content automatically chosen based on the products you have ordered from us in the past. However, GreenSound will not use Personal Information submitted through the Site or obtained through our other Services for automated decision-making, including profiling, which produces legal effects or similarly significantly affects the data subject. 
Retention: GreenSound will retain your Personal Information for the time period needed to accomplish the purpose for which GreenSound collected your Personal Information, and for as long thereafter as is permitted or required by applicable law. For additional information about the retention of your Personal Information, please contact management@GreenSound .net.cn. 
Unsubscribing From Emails: In addition, you may cancel or modify the email communications you have chosen to receive from GreenSound by following the instructions contained in emails from us. Alternatively, you may email GreenSound at unsubscribe@GreenSound .net.cn with your request, stating 'Unsubscribe' in the header and what email addresses you wish not to receive GreenSound emails. Within a reasonable period, we shall ensure that such email addresses are unsubscribed.
European Economic Area, the United Kingdom and Switzerland
The information in this section, as well as the information in the section above titled “All locations outside of the United States," applies to users in the European Economic Area, the UK, and Switzerland (referred to as the “EEA").
Cross-Border Data Transfers: The European Commission has not issued a determination that the United States ensures an adequate level of protection for Personal Information. As a result, GreenSound has implemented the new Standard Contractual Clauses, issued by the European Commission on June 4, 2021, to govern the cross-border transfer of Personal Information from the EEA. For transfers of Personal Information from the UK, GreenSound uses the UK's International Data Transfer Agreement. 
Individual Rights: EEA Individuals have the right to access their Personal Information collected by GreenSound and to request that GreenSound update, correct, or delete their Personal Information as provided by applicable law. EEA Individuals also have the right to object to, or restrict, GreenSound 's processing of their Personal Information. In addition, EEA Individuals have the right to data portability concerning their Personal Information.
EEA Individuals can exercise these rights through GreenSound 's Data Inquiry and Privacy Request website..  GreenSound will respond to such requests in accordance with applicable data protection law. If EEA Individuals believe that their Personal Information has been processed in violation of applicable data protection law, they have the right to lodge a complaint with the relevant data protection authority in the country where they reside, where they work, or where the alleged violation occurred. EEA Individuals also have the right to object to the processing of their Personal Information for purposes of GreenSound 's direct marketing or legitimate interests through GreenSound 's Data Inquiry and Privacy Request website.
EEA Individuals may withdraw their consent for the processing of their Personal Information, where GreenSound requires their consent as a legal basis for processing their Personal Information, at any time through GreenSound 's Data Inquiry and Privacy Request website..  Any withdrawal will apply only prospectively, and GreenSound will continue to retain the Personal Information that EEA Individuals provided before they withdrew their consent for as long as allowed or required by applicable law.
Data Protection Officer: GreenSound 's data protection officer in Germany is the Senior Manager, Health and Safety and can be reached at management@GreenSound .net.cn.
Brazil 
The information in this section, as well as the information in the section above titled “All locations outside of the United States," applies to residents of Brazil. Personal Information collected from residents of Brazil outside of this Site is stored by GreenSound in Brazil, at Rodovia Governador Leonel de Moura Brizola, nº 3401, Nova Santa Rita- RS/Brasil 92480-000.
Individual Rights: Residents of Brazil have the following rights with respect to the processing of Personal Information:
othe right to request confirmation of the processing of your Personal Information;
othe right to request access to your Personal Information;
othe right to request the correction and/or update of your Personal Information;
othe right to request the anonymization, blocking or elimination of your Personal Information that is unnecessary, excessive or processed in violation of the law;
othe right to request the transfer of your Personal Information from one service provider to another;
othe right to request the deletion of your Personal Information previously processed with your consent;
othe right to request an identification of the public and private entities to which the Company disclosed your Personal Information or with which the Company used a shared database containing your Personal Information; and
othe right to object to the processing of your Personal Information.
Residents of Brazil can exercise their privacy rights through GreenSound 's Data Inquiry and Privacy Request website. GreenSound will respond to such requests in accordance with applicable data protection law.
Residents of Brazil may use the contact information above, at any time, to withdraw their consent for the processing of their Personal Information where GreenSound requires their consent as a legal basis for processing their Personal Information. Any withdrawal will apply only prospectively, and GreenSound will continue to retain the Personal Information that residents of Brazil provided before they withdrew their consent for as long as allowed or required by applicable law.
Data Protection Officer / "Person in Charge": GreenSound 's data protection officer in Brazil, or “person in charge" of overseeing the processing of Personal Information, is the Company's IT Coordinator, who can be reached at Brazilmanagement@GreenSound .net.cn.  
People's Republic of China (PRC)
The information in this section, as well as the information in the section above titled “All locations outside of the United States," applies to residents of the PRC.
Cross-Border Data Transfers: By using the Site, you consent to the transfer across borders of your personal information, unless you withdraw your consent by contacting us as described below. Before transferring your Personal Information from the PRC to elsewhere outside of the PRC, GreenSound will ensure all required transfer mechanisms are in place to ensure an adequate level of protection for the transfer of your Personal Information to those entities outside the PRC.
Individual Rights: Residents of the PRC have the right to: (a) know how your Personal Information is being processed; (b) request access to your Personal Information; (c) request the correction of your Personal Information; (d) complete incomplete Personal Information; (e) request deletion of your Personal Information; (f) restrict or refuse the processing of your Personal Information unless such process is required by applicable law; (g) request the transfer of your Personal Information to a third party; and (h) withdraw your consent to the processing of your Personal Information; (i) request an explanation of the Personal Information handling rules in the PRC.
Residents of the PRC can exercise these rights through GreenSound 's Data Inquiry and Privacy Request website. GreenSound will respond to such requests in accordance with applicable data protection law. If Residents of the PRC believe that their Personal Information has been processed in violation of applicable data protection law, they have the right to lodge a complaint with the data protection authority.
Residents of the PRC may withdraw their consent for the processing of their Personal Information, where GreenSound requires their consent as a legal basis for processing their Personal Information, at any time through GreenSound 's Data Inquiry and Privacy Request website. Any withdrawal will apply only prospectively, and GreenSound will continue to retain the Personal Information that residents of the PRC provided before they withdrew their consent for as long as allowed or required by applicable law.
California 
This section of the Privacy Statement only applies to consumers who are residents of the state of California in the United States (“California Consumers") and it applies to all Personal Information of California consumers collected or disclosed for a business purpose by GreenSound 
Information We Collect & Our Business Purpose For Collection: In the last 12 months, GreenSound used the categories of Personal Information listed here that we collected from the following sources:
oDirectly from California Consumers
oFrom the technology providers detailed above
oFrom the third-party service providers detailed above
GreenSound collected the Personal Information of California Consumers for the business and commercial purposes listed here.
Disclosure of Personal Information We Collect: In the last 12 months, GreenSound disclosed the following categories of Personal Information about California Consumers to the following categories of third parties for a business or commercial purpose:
Category of Personal Information	
Category of Third Party
Contact Information & Identifiers
Service Providers, including providers of data analytics services, website providers, and e-commerce vendors
Government agencies, law enforcement, and other parties as required by law, including in litigation
Internet or Other Electronic Network Activity Information	Service Providers, including providers of data analytics services, and website providers
Location Information	Government agencies, law enforcement, and other parties as required by law, including in litigation
Commercial Information Payment (Financial) Information	Service Providers, including e-commerce vendor
Sensory information	Service Providers, including voice-to-text vendors
Product & Service Usage Information	Service Providers, including providers of data analytics services, and website provider
Government agencies, law enforcement, and other parties as required by law, including in litigation

No Sale Of Personal Information: GreenSound does not and will not sell Personal Information.
Your California Privacy Rights:
Right to Know
California Consumers have the right to submit a verifiable request to know:
The categories and specific pieces of Personal Information that GreenSound has collected about them;
The categories of sources from which GreenSound collected the Personal Information;
The categories of Personal Information that GreenSound sold or disclosed to a third party (other than a service provider) for a business purpose and the categories of recipients of that information; and
The business or commercial purposes for GreenSound 's collection, disclosure, or sale of the Personal Information.
Right To Delete
California Consumers have the right to submit a verifiable request for deletion of their Personal Information that GreenSound has collected from the Consumer.
Making Requests To Know Or For Deletion
GreenSound will respond to requests in accordance with applicable law if we can verify the identity of the individual submitting the request. California Consumers or their authorized agents can exercise their rights in the following ways:
Complete an online privacy request through our Data Inquiry and Privacy Request website
Send email to management@GreenSound .net.cn
Contact us at +1 (86) 13826536790
How We Will Verify Your Request
When you submit a request to know or a request to delete we apply the following process to verify your identity:
Step 1: Verification Email: GreenSound will send an email to the email address that you provided to register for our Services. 
Please read through this email and complete the verification criteria and once complete send a reply email back to GreenSound . 
The required criteria is described below:
oRead through the email that you receive from GreenSound . In the email we will ask you to reply with at least two pieces of information to verify your identity, 
dependent on the nature of your request and the sensitivity of the information that is the subject of your request.
oReply to the email and provide us with at least two (and in some cases three) pieces of information, 
GreenSound will match the information you provide to the information that the Company already maintains about you. 
GreenSound will only use the information that you provide for the purpose of verifying your identity.
oConfirm our summary of the actions that you want us to take (e.g., “delete my Personal Information").
oSend the reply to the email back to the original sender with the above answers to verify that you own the email address.
Step 2: After receiving the information above and confirming your identity, we take action to fulfill the request. 
In the unlikely situation that you submit a request to know or a request to delete and GreenSound does not have your email address,
 we will perform the steps listed above via phone. If we cannot verify your identity to a sufficient level of certainty to respond to your request, 
 we will let you know promptly and explain why we cannot verify your identity.
GreenSound 's Non-Discrimination Policy
California Consumers have the right not to be subject to discriminatory treatment by GreenSound for exercising their privacy rights under the California Consumer Privacy Act, 
and GreenSound will not discriminate on that basis. However, GreenSound may charge a California Consumer a different price or rate or provide a different level or quality of 
goods or services if the difference is reasonably related to the value provided to the California Consumer by the Consumer's Personal Information. If GreenSound does so, 
it will provide Consumers with any legally required notice.
California residents who use the Site may request that we provide certain information regarding our disclosure of your Personal Information to third parties for their direct marketing purposes. 
You can make such a request by e-mail to management@GreenSound .net.cn.
Questions
California Consumers who have questions about anything in this Privacy Statement can contact GreenSound at management@GreenSound .net.cn or 1 (86) 13826536790.
Changes To This Privacy Statement
If we change this Privacy Statement, we will post those changes on this page and update the Privacy Statement update date above. 
If we materially change this Privacy Statement in a way that affects how we use or disclose your Personal Information, 
we will provide a prominent notice of such changes and the effective date of the changes before making them.
`
          },
          {
            id: 2,
            title: 'CPRA Applicant Notice and Privacy Policy',
            content:
              `Notice at Collection and Privacy Statement for HR Individuals Who Reside in California
Last Updated: June 2023

GREENSOUND International Industries and its subsidiaries (“Company”) take your privacy seriously. We want you to know how we collect, use, 
and disclose your personal information.

California Notice at Collection: Company collects the personal information identified in Section 1 for the purposes identified in Section 3 and retains it for the period described in Section 5. 
We do not sell your personal information or disclose it for cross-context behavioral advertising. We also do not collect or process sensitive personal information for the purpose inferring characteristics about you.

Assistance For The Disabled

Alternative formats of this Privacy Statement are available to individuals with a disability. Please contact management@greensound.net.cn for assistance.

This Privacy Statement explains:

The categories of personal information we collect about you
The categories of sources from which we collect your personal information
The purposes for which we use your personal information
How we may disclose your personal information
How long we keep your personal information
Your privacy rights and how to exercise them
Changes to this Privacy Statement
Scope:

This Privacy Statement applies to the personal information of California residents who are (a) employees, 
(b) independent contractors, interns, and other individuals who perform work for Company (collectively “Non-Employees”), 
and (c) emergency contacts, and beneficiaries (“Related Contacts”), (all collectively, “HR Individuals”) in their role as HR Individuals. 
This Privacy Statement informs HR Individuals about the categories of personal information Company has collected about them in the preceding 
twelve months as well as the categories of personal information that the Company will collect about HR individuals in the future.

Except where the Privacy Statement specifically refers only to a specific category of HR Individuals, e.g., 
employees, this Privacy Statement refers to all categories of HR Individuals collectively.

"Personal information" means information that identifies, relates to, describes, is reasonably capable of being associated with, 
or could reasonably be linked, directly or indirectly, with a particular HR Individual or household.

"Personal information" does not include:

Information publicly available from government records or made publicly available by you or with your permission;
Deidentified or aggregated information;
Information excluded from the CPRA’s scope, such as :
protected health information covered by the Health Insurance Portability and Accountability Act (“HIPAA”) or 
the Health Information Technology for Economic and Clinical Health Act (“HITECH”) or medical information covered by
 California Confidentiality of Medical Information Act (“CMIA”); or
personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (“FCRA”), 
the Gramm-Leach-Bliley Act (“GLBA”), or the California Financial Information Privacy Act (“FIPA”).
1) THE CATEGORIES OF PERSONAL INFORMATION WE COLLECT

A. Employees

Identifiers, for example: full legal name, nickname or alias, preferred name, preferred pronoun, postal address, residence address, 
telephone number, mobile phone number, e-mail address, Social Security number, date of birth, passport number, driver’s license number
 or state identification card number, and other governmen
issued ID numbers, documents that establish identity, documents that establish employment authorization, signature, online identifier, 
Internet Protocol address, bank name and bank account number, personal credit card number, personal check, debit card number, 
billing address, personal vehicle make & model and license plate number photographs uploaded and stored on internal systems.
Professional or Employment-Related Information, for example: attendance, evaluations, performance reviews, discipline records, 
personnel files, expenses, education history including graduation date, corporate credit card details, membership in professional 
organizations, professional certifications, work eligibility in order to comply with legal requirements, and current and past employment history , 
seniority, training, employment start and ending dates, and job title.
Compensation and benefits information for employees, for example: salary, bonus and commission, equity compensation information, 
hours and overtime, leave information, bank details (for payroll and reimbursement purposes only), benefits in which you may be enrolled,
 and identifying information for dependents and beneficiaries.
Non-public educational information, for example: academic transcripts.
Commercial Information, for example: business travel and expense records.
Internet Activity Information, for example: Internet browsing and search history while using Company’s network, 
log in/out and activity on Company's electronic resources, interactions with Company’s Internet web site, application, 
or advertisement, and publicly available social media activity.
Sensory or Surveillance Data, for example: voice-mails, recordings of meetings or video-conferences, and footage from video surveillance cameras.
§ 1798.80: personal information described under Cal. Civ. Code § 1798.80 to the extent not already included in other categories here, 
such as benefit information to administer short and long-term benefits as well as other benefit plans.
Preferences, for example, hobbies and leisure activities, membership in voluntary/charitable/public organizations,
 and preferences regarding work tools, travel, hours, food for company events, etc.
Characteristics of Protected Classifications Under California or Federal Law for employees, for example: race, age,
 national origin, disability, sex, and veteran status as necessary to comply with legal obligations and to support diversity and inclusion programs; 
 disability, medical condition, and related medical conditions, as necessary to comply with Federal and California law related to 
 leaves of absence and accommodation; and marital and familial status as necessary to provide benefits to employees and for tax purposes.
Geolocation data, for example: (a) information that can be used to determine a company owned vehicle’s physical location; 
and (b) information that can be used to determine an employee’s physical location through badge security.
B. Non-Employees

Company collects the categories of personal information listed in Section 1.A, above, excluding the following categories: 
(a) Compensation and benefits information for employees and (b) Characteristics of protected classifications under California or federal law for employees.

In addition, Company collects the following personal information regarding Non-Employees:

Identifiers, for example real name, nickname or alias, preferred name, title, postal address, telephone number, 
fax number, mobile number, e-mail address, signature, Social Security number, date of birth, Internet Protocol address, MAC Address
Professional or Employment-Related Information, for example: real name, nickname or alias, preferred name, title, postal address,
 telephone number, fax number, mobile number, e-mail address, signature, Social Security number, date of birth, bank account information, 
 Internet Protocol address, MAC Address
§ 1798.80: personal information described under Cal. Civ. Code § 1798.80 to the extent not already included in other categories here, such as photographs
Internet Activity Information, for example: The Company collects information about Contractors’ use of the Internet or other similar network 
activity of company issued assets, including the following: browsing history, search history, log in/out and activity on Company electronic resources, and information regarding a Contractor’s interaction with an Internet web site, application, or advertisement, and publicly available social media activity.
Compensation: Amounts paid to Non-Employees for services rendered;
Bank account information
Geolocation data, for example: The Company collects geolocation data, including the following: ( (a) information that can be used 
to determine a company owned vehicle’s physical location; and (b) information that can be used to determine a Contractor’s physical location,
 for example, through a security badge
Sensory or Surveillance Data: voice-mails, recordings of meetings or video-conferences, and footage from video surveillance cameras.
C. Related Contacts

Company only collects contact information about emergency contacts.
Company may collect the following categories of personal information about spouses or domestic partners, dependents, and beneficiaries: 
(a) Identifiers; (b) Commercial Information if, for example, Company arranges travel for a dependent to attend a Company event; 
(c) Internet Activity Information if the individual uses Company electronic resources and web sites; (d) Sensory or Surveillance Data if the individual enters Company facilities; (e) § 1798.80 personal information, such as insurance policy numbers if the individual is covered by Company insurance or health information, for example, infectious disease testing when a Related Contact attends a Company event; and (f) Protected Categories of Personal Information, for example, childbirth to administer parental leave, marital status to pay taxes, and familial status to administer benefits.
2) THE CATEGORIES OF SOURCES FROM WHICH WE COLLECT YOUR PERSONAL INFORMATION

You, for example, in your application, forms you fill out for us, assessments you complete, surveys you submit, 
and any information you provide during your relationship with us.
Your spouse or dependent with respect to their own personal information.
Vendors and service providers, for example, law firms.
Affiliated companies, for example, when an employee works on a cross-enterprise team.
Third parties, for example, job references, business partners, professional employer organizations or staffing agencies, insurance companies.
Public internet sources, for example, social media, job boards, public profiles, and other public online sources
Public records, for example, court records, and credentialing and licensing organizations.
Automated technologies on Company’s electronic resources, for example, to track logins and activity across Company network.
Surveillance/recording technologies installed by Company, for example, video surveillance in common areas of Company facilities,
 global positioning system (“GPS”) technologies, voicemail technologies, webcams, audio recording technologies, and blue-tooth technologies, 
 any of these with consent to the extent required by law.
Government or administrative agencies, for example, law enforcement, public health authorities, California Department of Industrial Relations, 
Employment Development Department.
Acquired company, if Company acquired your employer, Company might collect personal information from that employer.
3) THE PURPOSES FOR WHICH WE USE YOUR PERSONAL INFORMATION

A. All HR Individuals

Managing Personnel, including:

(Not applicable to Related Contacts)

Administration, including:
To manage personnel and workforce matters
To communicate with the workforce
To plan and arrange work supplies and workspaces.
To fulfill recordkeeping and reporting responsibilities
For recruitment of new HR Individuals
To resolve internal grievances and disciplinary issues
To make business travel arrangements
To manage workforce-related emergencies, including health emergencies
Workforce development, including:
To screen workforce for risks to Company and continued suitability in their positions
To conduct surveys
Team building, including:
To maintain an internal workforce directory and for purposes of identification
To facilitate communication, interaction, and collaboration among HR Individuals
To arrange meetings and manage Company-sponsored events and public service activities
To promote Company as a place to work
Workforce reporting and data analytics/trend analysis
For workforce satisfaction
Monitoring, Security, and Compliance, including:

To monitor access to, and use of, Company facilities and information systems
To ensure compliance with Company policies
To conduct internal audits and investigations
To administer Company’s whistleblower hotline
To protect the safety and security of Company’s facilities, including preventing illicit activity
To report suspected criminal conduct to law enforcement and cooperate in investigations
To exercise Company’s rights under applicable law and to support any claim, defense, or declaration in a case or before a jurisdictional
 and/or administrative authority, arbitration, or mediation panel
Conducting Our Business, including:

(Not applicable to Related Contacts)

To engage in marketing, advertising, and promotion
For communications with prospective, current, and former customers
To provide a directory and contact information for prospective and current customers and business partners
For customer service purposes
To manage business expenses and reimbursements
To engage in project management
To conduct product and service training
To conduct research and development
To conduct quality assurance and improvement
For event planning
To engage in crisis management
Miscellaneous Other Purposes:

To efficiently manage and operate administrative, information technology, and communications systems, risk management and insurance functions,
 budgeting, financial management and reporting, and strategic planning;
To manage litigation involving Company, and other legal disputes and inquiries and to meet legal and regulatory requirements;
In connection with a corporate transaction, sale, or assignment of assets, merger, divestiture, or other changes of control or 
financial status of Company or any of its subsidiaries or affiliates;
To manage licenses, permits, and authorizations applicable to Company’s business operations; and
To protect the rights, property, or safety of Company, HR Individuals, customers, or others
B. Employees

1. Generally Applicable Purpose

Unless stated otherwise in section 3.B.2, below, we may use employees’ personal information for the following purposes:

Managing Employees, including:

Administration, including:
To set up and manage a personnel file
To manage performance
To administer compensation, bonuses, equity grants, other forms of compensation, and benefits (as permitted by law)
To manage vacation, sick leave, and other leaves of absence
To track hours and attendance
Employee development, including:
To provide, evaluate, and manage training and career development
To evaluate job performance and consider employees for other internal positions or promotions
To assist with professional licensing
To develop a talent pool and plan for succession
Team-building, including:
For diversity and inclusion programs
To arrange team-building and other morale-related activities
To design employee retention programs
2. Purposes Specific To Certain Categories Of Employees’ Personal Information

We may use the categories of employees’ personal information listed in this Section 3.B.2 for the purposes stated below:

Purposes For Using Employees’ Biometric Information:

Company uses biometric technology for security purposes to verify the identity of an individual entering certain Company facilities.
Purposes For Using Employees’ Geolocation Data:

To manage employee-related emergencies, to monitor the safety of an employee, to monitor compliance with Company policies.
Purposes For Using Employees’ Health Information:

To the extent necessary to comply with Company’s legal obligations, such as to accommodate disabilities.
To conduct a direct threat analysis in accordance with the Americans with Disabilities Act and state law
For workers’ compensation purposes
For occupational health surveillance
For occupational health and safety compliance and record-keeping
To conduct fitness-for-duty examinations
To administer leaves of absence and sick time
To provide a wellness program
To respond to an employee’s medical emergency
Purposes For Using Employees’ Protected Categories Of Information:

Company collects information about race, age, national origin, disability, sex, and veteran status as necessary to comply with legal obligations, 
including the reporting requirements of the federal Equal Employment Opportunity Act, The Office of Federal Contracting Compliance Programs
 (applicable to government contractors), and California’s Fair Employment and Housing Act. Company also collects information about disability 
 status to the extent an employee may need special assistance during emergencies from Company or from first responders.

Company also collects the following characteristics (in addition to those listed above) for its diversity and inclusion programs
 (including analytics): (a) religion, (b) sex, (c) gender, or related medical conditions, (d) sexual orientation, (e)disability,
  (f) gender identity, (g) gender expression, (h) marital status, (i) age, (j) familial status

In addition, Company uses this personal information for purposes including:

with respect to disability, medical condition, familial status, marital status,: as necessary to comply with Federal and California law
 related to leaves of absence and accommodation;
with respect to military and veteran status: as necessary to comply with leave requirements under applicable law and for tax purposes;
with respect to age: incidentally to the use of birth date for birthday celebrations and identity verification;
with respect to religion and medical conditions: as necessary for accommodations under applicable law;
with respect to protected classifications, such as national origin: to the extent this information is contained in documents that
 you provide in I-9 documentation; and
with respect to marital status and familial status: for Company events and as necessary to provide benefits and for tax purposes.
Company collects personal information about membership in protected categories on a purely voluntary basis, except where required by law,
 and uses the information only in compliance with applicable laws and regulations.

C. Non- Employees

Managing Non- Employees including:

To evaluate the individual’s qualifications for engagements, including licensure and certifications
To negotiate and execute the agreement with the individual
To provide orientation and familiarization with Company’s working environment
To administer the contractual relationship, including payments
To manage absences
D. Related Contacts

Spouse and Dependents/Beneficiaries

To manage and administer benefits
To communicate with the individual and manage the relationship
To arrange travel to, and manage participation in, Company events
Emergency Contacts

To communicate in the event of an emergency involving the individual who provided the emergency contact’s information
E. Deidentified Information

At times, Company converts personal information into deidentified information using reasonable measures to ensure that the deidentified
 information cannot be associated with the individual (“Deidentified Information”). Company maintains Deidentified Information in a deidentified 
 form and does not attempt to reidentify it, except that Company may attempt to reidentify the information solely for the purpose of determining 
 whether its deidentification processes ensure that the information cannot be associated with the individual. Company prohibits vendors, 
 by contract, from attempting to reidentify Company’s Deidentified Information.

4) HOW WE MAY DISCLOSE YOUR PERSONAL INFORMATION

Company generally maintains information related to its personnel as confidential. However, from time to time, Company may have a legitimate
 business need to disclose personnel information for one of the purposes listed in Section 2, above, to one or more of the categories of recipients
  listed below. In that event, Company discloses information within the categories of personal information above only to the minimum extent 
  necessary to achieve the purpose of the disclosure and only if the disclosure is permitted by the CPRA and other applicable laws.

Your direction: We may disclose your personal information to third parties at your direction.
Service providers and contractors: Company discloses your personal information to service providers and contractors to assist 
us in meeting our business needs and contractual and legal obligations.
Company discloses your personal information to service providers and contractors only subject to written contracts in compliance 
with the CPRA and any other applicable law.
Service providers and contractors include auditors, administrative service providers, law firms, travel agencies, benefits providers,
 and any other entity providing services to Company.
Affiliated companies: Other companies within the GREENSOUND International Industries family of companies.
Clients and customers: This may include, for example, disclosing a sales representative’s contact information to clients.
Business partners: For example, Company might disclose your business contact information to a co-developer of a new product or 
service with which you will be working.
Government or administrative agencies: These may include, for example:
Internal Revenue Service to pay taxes;
Employment Development Department as required for state payroll taxes and to respond to unemployment or state disability insurance claims;
National Labor Relations Board, for example, to notify the NLRB that an employee is represented by a union;
OSHA/CalOSHA as required to report work-related death or serious injury or illness;
Department of Fair Employment and Housing as required to respond to employment charges; and
California Department of Industrial Relations as required to resolve workers’ compensation claims.
Public: Company may disclose your personal information to the public as part of a press release, for example, to announce promotions or awards.
 If you do not want your personal information in press releases, please contact management@greensound.net.cn. 
 Company does not disclose sensitive personal information to the public.
Required Disclosures: We may be required to disclose personal information (a) in a court proceeding, (b) in response to a court order, 
subpoena, civil discovery request, other legal process, or (c) as otherwise required by law.
Legal Compliance and Protections: We may disclose personal information when we believe disclosure is necessary to comply with the law or
 to protect the rights, property, or safety of Company, our users, or others.
No sales and no “sharing”, i.e., disclosure for cross-context behavioral advertising:

Company does not sell the personal information of any HR Individuals nor disclose their personal information for cross context behavioral advertising.

5) HOW LONG WE KEEP YOUR PERSONAL INFORMATION

Company keeps your personal information no longer than necessary for the purposes described in Section 2 above and in accordance 
with our Retention Schedules available unless Company is required to retain your personal information longer by applicable law or regulation, 
by administrative needs, by legal process, or to exercise or defend legal claims.

6) YOUR PRIVACY RIGHTS AND HOW TO EXERCISE THEM

a. Your California Privacy Rights

Subject to applicable law, HR Individuals have the following rights:

Right to Know: You have the right to submit a verifiable request up to twice in a 12-month period for specific pieces of your personal 
information obtained from you and for information about the Company’s collection, use, and disclosure of your personal information. In addition,
 you have a right to know the categories of your personal information that Company Please note that the CPRA’s right to obtain specific pieces 
 does not grant a right to the whole of any document that contains personal information, but only to items of personal information. Moreover, 
 HR Individuals have a right to know categories of sources of personal information and categories of third parties to which personal information 
 is disclosed, but not the individual sources or third parties.
Right to Delete: You have the right to submit a verifiable request for the deletion of personal information that you have provided to Company.
Right to Correct: You have the right to submit a verifiable request for the correction of inaccurate personal information maintained by Company, 
taking into account the nature of the personal information and the purposes of processing the personal information.
b. How to Exercise Your Rights

Company will respond to requests to know, delete, and correct in accordance with applicable law if it can verify the identity of the individual
 submitting the request. You can exercise these rights in the following ways:

Email management@greensound.net.cn
c. How We Will Verify Your Request:

The processes that we follow to verify your identity when you make a request to know, correct, or delete are described below. 
The relevant process depends on how and why the request is submitted.

If you submit a request by any means other than through a password-protected account that you created before the date of your request, 
the verification process that we follow will depend on the nature of your request as described below:

Requests To Know Categories Or Purposes: We will match at least two data points that you provide with your request, or in response to your 
verification request, against information about you that we already have in our records and that we have determined to be reliable for purposes 
of verifying your identity. Examples of relevant data points include your mobile phone number, your zip code, or your employee identification number.
Requests To Know Specific Pieces Of Personal Information: We will match at least three data points that you provide with your request, 
or in response to our request for verification information, against information that we already have about you in our records and that 
we have determined to be reliable for purposes of verifying your identity. In addition, we may require you to sign a declaration under 
penalty of perjury that you are the individual whose personal information is the subject of the request.
Requests To Correct or Delete Personal Information: Our process for verifying your identity will depend on the risk level 
(as determined by Company) associated with the personal information that you ask us to correct or delete. For low-risk personal information,
 we will require a match of two data points as described in Point No. 1, above. For higher risk personal information, 
 we will require a match of three data points and a signed declaration as described in Point No. 2, above.
We have implemented the following additional procedures when verifying the identity of requestors:

If we cannot verify your identity based on the processes described above, we may ask you for additional verification information. 
If we do so, we will not use that information for any purpose other than verification.
If we cannot verify your identity to a sufficient level of certainty to respond to your request, we will let you know promptly 
and explain why we cannot verify your identity.
d. Authorized Agents

If an authorized agent submits on your behalf a request to know, correct, or delete, the authorized agent must submit with 
the request another document signed by you that authorizes the authorized agent to submit the request on your behalf. In addition, 
we may ask you or your authorized agent to follow the applicable process described above for verifying your identity. 
You can obtain the “Authorized Agent Designation” form by contacting us at management@greensound.net.cn

e. Company’s Non-Discrimination And Non-Retaliation Policy

Company will not unlawfully discriminate or retaliate against you for exercising your rights under the California Privacy Rights Act.

7. CHANGES TO THIS PRIVACY STATEMENT

If we change this Privacy Statement, we will post those changes on this page and update the Privacy Statement modification date above.

For More Information

For questions or concerns about Company’s privacy policies and practices, please contact us at management@GREENSOUND.net.cn`
          },
          {
            id: 3,
            title: 'Terms Of Use',
            content:
              `Last Modified: 05-03-18

These Terms of Use (“Terms") apply to your access and use of the services, applications, products, or websites associated with these 
Terms (collectively “Services"), which are owned and operated by Greensound International Industries, Incorporated or its affiliates. 
These Terms are important and affect your legal rights, so please read them carefully. By accessing and using the Services, 
you agree to be bound by these Terms and any terms incorporated herein by reference, including our Privacy Statement and Terms of Sale.
 If you do not agree to these Terms, you may not access or use the Services.

These Terms do not alter in any way the terms or conditions of any other agreement you may have with us in respect of any products,
 services or otherwise. If you are using the Services on behalf of any person or entity, 
 you represent and warrant that you are authorized to accept these Terms on such person or entity's behalf and that such person or entity 
 agrees to be responsible to us if you or such person or entity violates these Terms.

Privacy
We respect the privacy of our users. The information we collect through the Services is subject to our Privacy Statement.
 Please refer to our Privacy Statement which explains how we collect, use, and disclose your information.

Additional Terms
Certain features of the Services, such as certain mobile applications, additional services, promotions, and offers, 
may be subject to additional terms and conditions (“Additional Terms") presented in conjunction with them. 
We may present Additional Terms to you through the Services. Regardless of how they are presented to you, 
we require that you agree to Additional Terms before using these features of the Services. Unless otherwise specified in Additional Terms, 
all Additional Terms are incorporated into these Terms. If you do not agree to Additional Terms, 
you will not have access to the aspect of the Services to which they relate. These Terms and Additional Terms apply equally.
 If, however, any Additional Term is inconsistent with any provision of these Terms, the Additional Term will prevail 
 but only for the service(s) to which the Additional Terms apply.

All purchases through the Services or other transactions for the sale of goods formed through the Services or as a result of visits 
made by you are governed by our Terms of Sale, which are hereby incorporated into these Terms.

Changes to the Terms of Use
We may revise and update these Terms from time to time in our sole discretion. We will notify you when we make changes to these Terms 
and give you the opportunity to review before continuing to use the Services. All changes are effective immediately when we post them, 
and apply to all access to and use of the Services thereafter. You agree that we will not be liable to you for any change or discontinuance,
 in whole or in part, of the Terms, or the Services.

Your continued use of the Services following the posting of revised Terms means that you accept and agree to the changes.
 You are expected to check this page each time you access the Services so that you are aware of any changes, as they are binding on you.
  You further agree to review these Terms periodically and to make yourself aware of these changes.

Key Definitions
“Greensound Content" means all content, features and functionality (including but not limited to all information, code, content, 
drawings, graphics, metadata, meta tags, marking information, page format and style, pictures, phrases, product descriptions, software, 
text, displays, images, video and audio, and the design, selection and arrangement thereof, the compilation of all of the foregoing and 
all intellectual property rights and goodwill associated therewith) available through the Services, or otherwise provided to you by or 
on our behalf through the Services. Greensound Content excludes Personal Data.

“Personal Data" " is any information that can be used to identify you, either alone or in combination with other information. 
Personal Data does not include aggregate information or other anonymized information (including metadata) collected by us that
 cannot be used to identify you, even if such information was derived from your Personal Data.

“Your Content" is the information, comments, photos, images, video, data, text, and other content that you may post, upload, store,
 share, send or display on the Services.

Eligibility
The Services are offered and available to users who are 18 years of age or older. By using the Services, you represent and warrant 
that you are of at least 18 years of age.

Accessing the Services and Account Security
We may withdraw or amend the Services, and any service or material we provide on the Services, in our sole discretion without notice. 
We will not be liable if all or any part of the Services is unavailable at any time, or for any time period. From time to time, 
we may restrict access to some parts of the Services, or the entire Services, to users, including registered users.

You are responsible for:

Making all arrangements necessary for you to have access to the Services.
Ensuring that all persons who access the Services through your internet connection are aware of these Terms and comply with them.
To access the Services or some of the resources they offers, you may be asked to provide certain registration details or other information. 
It is a condition of your use of the Services that all the information you provide on the Services is correct, current and complete. 
You agree that all information you provide to register with the Services or otherwise, including but not limited to through the use of
 any interactive features on the Services, is governed by these Terms, and you consent to all actions we take with respect to your 
 information consistent with these Terms.

If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, 
you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that 
your account is personal to you and agree not to provide any other person with access to the Services or portions of it using your user name,
 password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password
  or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular
   caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other
    personal information.

We may disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for 
any or no reason, including if, in our sole discretion, you have violated any provision of these Terms.

Intellectual Property Rights
Greensound International Industries, Incorporated (and its licensors) owns all Services and Greensound Content, protected by United States 
and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. We reserve all rights
 in and to the Greensound Content. Except as expressly granted to you under these Terms, we do not grant you any right, title or interest in
  or to any part of the Greensound Content.

You will not copy, reproduce, publish, distribute, transmit, disseminate, display, exhibit, edit, modify, and create derivative works from,
 or exploit in any way, any part of the Greensound Content. You will not frame or utilize framing techniques to enclose our trademark, logo,
  or other proprietary information. You will not use any meta tags or any other "hidden text" utilizing our name or trademarks. 
  You will not delete, remove or otherwise alter any copyright, trademark or other proprietary notice appearing in or on the Greensound Content. 
  You will not take any action to jeopardize, limit or interfere in any manner with our rights in and to the Greensound Content.
   You agree not to portray us, our products or the Services in a false, misleading, derogatory or otherwise offensive manner. 
   We reserve the sole and exclusive right at our discretion to assert claims against third parties for infringement or misappropriation of
    our intellectual property rights in the Greensound Content. The Services may contain Greensound Content owned or licensed by third parties.
     All restrictions set forth in these Terms equally apply to all Greensound Content owned or licensed by third parties.

Our name, the terms, logos, trademarks, and all related names, logos, product and service names, designs and slogans are our trademarks
 (or our affiliates or licensors' trademarks). You must not use such marks without our prior written permission. All other names, logos, 
 product and service names, designs and slogans on the Services are the trademarks of their respective owners.

Grant of License
Subject to the restrictions in these Terms, you may use the Services for your personal, non-commercial use. You will not, 
nor will you assist or direct any third person to: (i) extract, separate, remove or otherwise copy the Greensound Content; (ii) disassemble,
 decompile, reverse engineer or otherwise convert any part of the Greensound Content to source code or a human-perceivable form; (iii) adapt,
  modify or create a derivative work of the Greensound Content; (iv) distribute, encumber, lease, rent, sell, transmit, transfer, 
  or otherwise dispose of the Greensound Content, in whole or in part; or (v) use the Greensound Content for any other purpose.

If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Services in breach of the Terms,
 your right to use the Services will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. 
 No right, title or interest in or to the Services or any content on the Services is transferred to you, and we reserve all rights not expressly 
 granted. Any use of the Services not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark and other laws.

Prohibited Uses
You must use the Services only for lawful purposes and in accordance with these Terms. You agree not to use the Services:

In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, 
any laws regarding the export of data or software to and from the US or other countries).
For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, 
asking for personally identifiable information or otherwise.
To send, knowingly receive, upload, download, use or re-use any material which does not comply with these Terms.
To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail",
 “chain letter" or “spam" or any other similar solicitation.
To impersonate or attempt to impersonate us, any of our employees, another user or any other person or entity (including, without limitation, 
by using e-mail addresses or screen names associated with any of the foregoing).
To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, 
may harm us or our users or expose them to liability.
Additionally, you agree not to:

Use the Services in any manner that could disable, overburden, damage, or impair the Services or interfere with any other party's use of the Services,
 including their ability to engage in real time activities through the Services.
Use any robot, spider or other automatic device, process or means to access the Services for any purpose, including monitoring or copying any 
of the material on the Services.
Use any manual process to monitor or copy any of the material on the Services or for any other unauthorized purpose without our prior written consent.
Use any device, software or routine that interferes with the proper working of the Services.
Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.
Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Services, the server on which the Services are stored, 
or any server, computer or database connected to the Services.
Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.
Otherwise attempt to interfere with the proper working of the Services.
Your Content
The Services may allow you to post, submit, publish, display or transmit to other users (collectively, “post") Your Content on or through the Services. 
You agree to observe and follow the following posting rules:

Do not post any content that includes information that is false, misleading, inaccurate, fraudulent, and deceptive or that promotes illegal activity.
Do not solicit any Personal Data from other users of the Services.
Do not post any content or information in violation of any laws.
Do not insult or flame any other users of the Services. Do not post content that is offensive, libelous, defamatory, indecent, harmful, harassing, 
intimidating, threatening, hateful, abusive, vulgar, obscene, pornographic or sexually explicit.
Post only constructive information. Do not speculate or post rumors about us, our products or any other person or entity.
Do not post information that you do not own or control all rights thereto. You are responsible for all content that you post.
Your Content that you post to the Services is considered non-confidential and non-proprietary. By posting Your Content to the Services, 
you grant us and our affiliates, our third-party service providers, and our retail partners and any person acting on our or their 
behalf (collectively and including us, the “Licensed Parties") a perpetual, non-exclusive, non-revocable, royalty-free, fully paid-up, 
worldwide right to adapt, modify, create derivative works, display, distribute, disclose, sublicense, assign, use, and commercialize Your Content 
in any manner and in any media without obligations to you. Your use of the Services constitutes your consent to allow us to use and store Your Content.

If Your Content contains Personal Data, we will treat such Personal Data in accordance with our Privacy Policy.

You hereby represent and warrant that (i) you own all rights in and to Your Content, (ii) you have permission from all person(s) appearing in
 Your Content to grant the rights granted herein; (iii) you are over the age of 18, and (iv) the Licensed Parties' use of Your Content as
  described herein will not violate the rights of any third party or any law. You hereby release, discharge and agree to hold the Licensed
   Parties harmless from any liability related in any way to the Licensed Parties' use of Your Content.

You understand and acknowledge that you are responsible for Your Content, and you, not us, have full responsibility for such content, 
including its legality, reliability, accuracy and appropriateness. We cannot and do not undertake to review all material before it is 
posted on the Services, and cannot ensure prompt removal of objectionable material after it has been posted. We have no liability or 
responsibility to anyone for performance or nonperformance of the activities described in these Terms.

Social Media Posting
By posting and uploading content to social media sites that you have tagged with our brands and hashtags, you are allowing us to search
 for your hashtagged postings (“Social Media Posting").We may contact you directly, either publicly or privately, through the social 
 media platform on which the Social Media Posting was located and request your permission to use the Social Media Posting. 
 If you grant us permission as outlined in the request we make, then you grant the Licensed Parties the same right and make the 
 same representations and warranties to the Social Medial Posting as you would with Your Content. You hereby release, discharge and 
 agree to hold the Licensed Parties harmless from any liability related in any way to the Licensed Parties' use of your Social Media Posting.

Our use of your Personal Data from your Social Media Posting will comply with our Privacy Policy.

Monitoring and Enforcement; Termination
We may:

Remove or refuse to post Your Content for any or no reason in our sole discretion.
Take any action with respect to Your Content that we deem necessary or appropriate in our sole discretion, including if we believe that 
Your Content violates these Terms, infringes any intellectual property right or other right of any person or entity, 
threatens the personal safety of users of the Services or the public or could create liability for us.
Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, 
including their intellectual property rights or their right to privacy.
Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services.
Terminate or suspend your access to all or part of the Services for any or no reason, including without limitation, any violation of these Terms.
Without limiting the foregoing, we may fully cooperate with any law enforcement authorities or court order requesting or directing us
 to disclose the identity or other information of anyone posting any materials on or through the Services. YOU WAIVE AND HOLD HARMLESS US 
 AND OUR AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY US OR ANY OF THE FOREGOING PARTIES
  DURING OR AS A RESULT OF OUR INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER US OR SUCH PARTIES 
  OR LAW ENFORCEMENT AUTHORITIES.

Reliance on Information Posted
The information, including Greensound Content, presented on or through the Services is made available solely for general information purposes.

We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Services,
 or by anyone who may be informed of any of its contents.

The Services may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, 
syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials,
and all articles and responses to questions and other content, other than the content we provide, are solely the opinions 
and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect our opinion.
 We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.

Changes to the Services
We may update the content on the Services, including Greensound Content, from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Services may be out of date at any given time, and we are under no obligation to update such material.

Linking to the Services and Social Media Features
You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.

The Services may provide certain social media features that enable you to:

Link from your own or certain third-party sites to certain content on the Services.
Send e-mails or other communications with certain content, or links to certain content, on the Services.
Cause limited portions of content on the Services to be displayed or appear to be displayed on your own or certain third-party sites.
You may use these features solely as we have provided and solely with respect to the content that these features are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:

Establish a link from any site that is not owned by you.
Cause the Services or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.
Link to any part of the Services other than the homepage.
Otherwise take any action with respect to the materials on the Services that is inconsistent with any other provision of these Terms.
The Services from which you are linking, or on which you make certain content accessible, must comply in all respects with these Terms.

You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We may withdraw linking permission without notice.

We may disable all or any social media features and any links at any time without notice in our discretion.

Links from the Services
If the Services contain links to other sites and resources provided by third parties, these links are provided for your convenience only. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party sites linked to the Services, you do so entirely at your own risk and subject to the terms and conditions of use for such third party sites.

Geographic Restrictions
We are based in Connecticut, United States with teams in different parts of the world. We make no claims that the Services or any Greensound Content is accessible or appropriate outside of the United States. Access to the Services may not be legal by certain persons or in certain countries. If you access the Services from outside the United States, you understand that any data, including Personal Data that you transmit to us or we collect from you will be collected from and stored in either the United States or wherever our teams are located.

Our Usage and Storage of Information
We may establish standard practices and limits regarding the use of the Services, including the type of user-posted content permitted on the Services, maximum number of days user-created content is displayed, accessible or retained by us or the maximum number of e-mails an account may send or receive and the maximum disk-space allotted to each end user. We have no liability for the preservation or deletion of any content maintained by the Services or otherwise provided by you to us through use of the Services. We may modify the usage and storage of content and information from time to time, in accordance with applicable law.

Disclaimer of Warranties
YOUR USE OF THE SERVICES, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES IS AT YOUR OWN RISK. THE SERVICES, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES ARE PROVIDED ON AN “AS IS" AND “AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. EXCEPT AS PROHIBITED BY LAW, WE DISCLAIM ALL WARRANTIES OF EVERY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WE MAKE NO REPRESENTATION OR WARRANTY WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICES. WE MAKE NO REPRESENTATION OR WARRANTY THAT THE SERVICES, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR CONTENT WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.

The information presented on or through the Services is made available solely for general information purposes. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other users of the Services, or by anyone who may be informed of any of its contents.

The Services may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators or reporting services. All statements and opinions expressed in these materials, and all articles and responses to questions and other content, other than the content we provide, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect our opinion. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.

THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

Limitation on Liability
IN NO EVENT WILL WE, OUR AFFILIATES OR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES, ANY SITES LINKED TO IT, Greensound CONTENT OR CONTENT OF OTHER SITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR SUCH OTHER SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. HOWEVER, IF WE ARE FOUND LIABLE TO YOU FOR ANY DAMAGE OR LOSS WHICH IS IN ANY WAY CONNECTED WITH YOUR USE OF THE SERVICES OR Greensound CONTENT, OUR LIABILITY WILL NOT EXCEED $100.00 USD.

THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

Indemnif ication
You agree to defend, indemnify and hold harmless us, our parent company, shareholders, affiliates, licensors and service providers, and our and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Services, including, but not limited to, Your Content, any use of Greensound Content, services and products other than as expressly authorized in these Terms or your use of any information obtained from the Services. This obligation will survive the termination or modification of these Terms.

Governing Law and Jurisdiction
These Terms of Use are governed by, and construed in accordance with, the laws of the State of New York without regard to any conflicts of law rules. Any controversy or claim arising out of or relating to these Terms of Use or the Services will be settled by arbitration in accordance with the Arbitration Rules of the American Arbitration Association and conducted by an arbitrator selected by the parties concerned in the arbitration. The seat of the arbitration will be in the Borough of Manhattan, New York. The award of the arbitrator will be final and binding. Judgment upon the award rendered by the arbitrators may be entered in any court having jurisdiction thereof. The parties waive any right to appeal the arbitral award, to the extent a right to appeal may be lawfully waived. Each party retains the right to seek judicial assistance: (a) to compel arbitration; (b) to obtain interim measures of protection pending arbitration; and (c) to enforce any decision of the arbitrators, including the final arbitral award. The prevailing party in the arbitration is entitled to receive reimbursement of its reasonable expenses, including attorney's fees, incurred in connection therewith.

No Class Actions
You may only resolve disputes with us on an individual basis, and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action.

Limitation on Time to File Claims
ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.

Waiver and Severability
Our failure to enforce or exercise any of these terms is not a waiver of that section. If a particular term is not enforceable, the unenforceability of that term will not affect any other terms.

Entire Agreement
These Terms, Privacy Policy, Terms of Sale and any Additional Terms constitute the sole and entire agreement between you and us with respect to the Services and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Services.

Notice of Infringement Clai ms
If you believe that your work has been improperly copied and posted to the Services, please provide us with your name, address, telephone number, e-mail address, a physical or electronic rendering of your signature, a description of your copyrighted work, a description of the location of your copyrighted work on the Services and a statement by you made under penalty of perjury that the above information is accurate and that you are the copyright owner or otherwise authorized to act on behalf of the copyright owner.

Your Comment s and Concerns
You may notify us in writing of any objectionable content. We will make a good-faith effort to investigate all allegations of objectionable content that violates the Terms but we do not represent or warrant that we will take any action relating thereto.

Notices for Complaints
Send all legal notices under these Terms to:

Greensound International Industries, Incorporated

6th_floor_a,_xinghuang_building,_no.8_road,_guangming_new_district,_shenzhen

+86 13826536790

Thank you for using the Services.`
          },
          {
            id: 4,
            title: 'Cookies Statement',
            content:
              `Cookie Policy
Last Modified: April 5, 2022

This cookie policy applies to all Sites and mobile applications operated by or on behalf of Greensound.  Our Sites use a number of different cookies and other tracking technologies. This Cookie Policy explains the following:

What are cookies?
Cookies we use and why we use them
Your cookies choices
Questions and updates 
What are cookies?
Cookies are small files that are downloaded to your device when you visit a website or mobile application. The cookie then sends information back to the website operator or to another website operator that recognizes that cookie. Cookies are useful because they allow a website to recognize a user's device. Cookies do lots of different jobs, like letting you navigate between pages efficiently, remembering your preferences, and generally improving the user experience.  

We also use other tracking technologies called web beacons, embedded scripts, and flash cookies. Sometimes these technologies are also called cookies, but they are not quite the same.

Web Beacons (also known as tracking pixels): Web beacons are small graphic images, also known as “Internet tags" or “clear gifs" that may be embedded in web pages and email messages. Web beacons may be used to monitor the number of visitors to a website, to track how users navigate a website, or to count how many emails were actually opened or specific pages or links were actually viewed.
 
Embedded Scripts: An embedded script is programming code designed to collect information about a user's interactions with a website. Typically, the script is temporarily downloaded onto a computer from the host website's server (or the server of a third-party service provider), and is active only while the visitor is connected to the website, then deleted or deactivated thereafter. 
You can find more information about tracking technologies at: www.allaboutcookies.org and www.youronlinechoices.eu.


Cookies we use and why we use them
Categories of cookies

Our cookies categorized by function. We use four types of tracking technologies on our Sites:

Essential Cookies.
These are tracking technologies that are strictly necessary for the functioning of the Sites or for performing services that an individual user has requested. Some examples of functions performed by essential cookies are cookies that remember that you have already logged into your Greensound account as you navigate from web page to web page in the Sites. An essential cookie might also record that you have chosen to refuse cookies.

Analytical Cookies
These tracking technologies collect information about how visitors use a website, for instance which pages visitors go to most often, and if they get error messages from web pages.

Functional Cookies
These tracking technologies allow the website to remember choices users make and to provide enhanced, personalized features. For example, to allow you to use Greensound's live chat and customer support messaging features, or to track whether you have completed a poll or survey on our Sites.

Targeting / Advertising cookies
Targeting/advertising cookies are tracking technologies used to deliver advertisements relevant to the user. For example, we use cookies that remember what you viewed on our Sites in order that we may serve you relevant advertisements for products that might interest you. We also use cookies that tell us whether you've seen an advertisement and interacted with it, for example, by clicking on it.

Our cookies categorized by persistence. Cookies can be categorized by how long they remain on your device. All cookies have expiration dates in them that determine how long they stay in your browser. There are two broad categories of duration:

a. Persistent cookies
Persistent cookies remain on your device until deleted manually or automatically.

b. Session cookies
Session cookies remain on your device until you close your browser when they are automatically deleted.

We use both persistent and session cookies.

Our cookies categorized by who places them. We use both first-party cookies and third-party cookies. First-party cookies are cookies placed on your device by our Sites. Third-party cookies are cookies placed by another website when you visit our Sites (our websites). Greensound has relationships with third-party advertising companies to place advertisements on this Site and other websites, and to perform tracking and reporting functions for this Site and other websites. These third-party advertising companies may place cookies on your computer when you visit our website or other websites so that they can display targeted advertisements to you. Cookies placed by third parties also collect usage data about the use of our Sites and provide services, such as remembering that you logged into your account or the location you selected as your preferred location.

Information collected by our cookies. Tracking technologies on our Sites collect several types of information, including information about your usage of our Sites, details about your device, and your IP address. Usage of our Sites might include the time at which your device accesses our Sites, the page you visit, how long you spend on each page, and the links you click. Cookies might also collect the link that brought you to our Sites and webpage you visit after you leave our Sites. Details about your device might include the type of browser you used to access our Sites, the version of that browser, and what kind of device you use. An IP address is the number assigned to your device, for example your laptop or mobile phone, when your device accesses the Internet. Your device may have a static IP address, which means that your device always has the same IP address. Or it may have a dynamic IP address, which means that the IP address changes each time the device accesses the Internet.

To the extent that the information collected by our tracking technologies is personal data under applicable data protection law, we treat it as personal data. If we link information collected through tracking technologies with any personal data you provide to us through our Sites, e.g., through submitting a form, we also treat that information as personal data.  For information on how we handle personal data collected through our Sites, please see our Privacy Statement

Sharing information. We use cookies provided by third-party service providers to assist us in better understanding our visitors.  These cookies collect usage data, such as the time users spend on a web page. The third-party service providers use this information to provide us with reports about Site traffic and interactions. Greensound then uses that information to offer better Site experiences and tools in the future.

Greensound also may share information collected from cookies placed by our Sites with other third parties hired to perform services for us, such as marketing service providers, data security consultants, and website development consultants. 

Cookies and Third-Party Advertisements. As noted above (“Our cookies categorized by who places them"), third parties place cookies on our Sites and collect information from those cookies. These third parties use tools including cookies and web beacons to provides services, such as 'remembering' a user's preferences, to analyze Site usage, and to determine the effectiveness of their advertisements and the types of ads that would be of greatest interest to you. The tools allow them to track some data relating to your computer and your Internet browsing on the Site or other websites that participate in their networks. This data may include your computer's IP Address, the Site addresses visited, the browser type used, your computer's operating system, and the date, time, and duration of the visit to each page.

Some third parties operate, or work with, cookie-based ad networks that place advertisements on other websites and online services that you visit.  For more information about third-party Internet advertising companies (also called “ad networks" or “network advertisers"), please visit the Network Advertising Initiative (NAI) at http://www.networkadvertising.org. 

Your cookie che rely on the consent of users in processing personal information related to cookie collection. In some countries, users have rights to decline cookies and other tracking technologies when they visit websites. For example, in the European Economic Area, users have the right to reject the installation of all cookies but essential cookies on their devices.

In your cookie consent manager and browser security settings, you can adjust what cookies you want to accept or refuse at any time. However, please be advised that if you disable certain cookies, some of the features of our Sites may not function properly or be available to you, such as those which require identification, and refusing all or certain cookies may result in a more limited experience of some of the services offered on the Site.

Users may withdraw consent at any time by changing their settings. Please note that our cookies options and tools vary by region and country in accordance with applicable law.

A. Choices provided by Greensound

For websites directed to countries with the above-mentioned rights, we obtain consent from users for cookies and offers choices regarding cookie collection. Our cookies preference tool allows users to choose the types of cookies installed on their device.

Choices Regarding Ad Networks. We use third-party analytics, including Google Analytics, to better understand how users engage with our Sites. You also can opt out of the use of cookies from Google Analytics here and learn more about how Google uses personal data here

Many third-party Internet advertising companies allow you to opt out of online interest-based advertisements using the following tools:

Network Advertising Initiative (NAI) 
Digital Advertising Alliance in the U.S. 
Digital Advertising Alliance of Canada in Canada 
European Interactive Digital Advertising Alliance in Europe 
Browser Controls. In addition, your browser or device may offer settings that allow you to decline cookies and to delete them. These controls vary by browser, and manufacturers may change both the settings they make available and how they work at any time. As of November 2021, you may find additional information about the controls offered by popular browsers at the links below:

Google Chrome
Internet Explorer
Firefox 
Safari 
Safari Mobile 
Opera 
Certain parts of our Sites may not work properly if you have disabled browser cookie use. In addition, using the browser settings to reject cookies may also cause your browser to reject cookies from all websites, not just our Sites. 

Questions and Updates
If you have any questions about our Cookie Policy, we'll do our best to answer them. Here's how to contact us by email at the following address: management@Greensound.net.cn

If we change our Cookie Policy, we will post those changes on this page and update the Cookie Policy modification date above. If we materially change this Cookie Policy, we will notify you by prominently posting a notice of such changes before making them and by stating the effective date of the changes.`
          }
        ],
        data1:[],
        activeKey: 0,
        navShow: true,
        io: null,
        slide: [require('../assets/images/privacy.jpg')]
      };
    },
    //   动态数据的写法：如果数据是接口返回的，则可以通过一下方式来写
    //   watch: {
    //     data() {
    //       this.$nextTick(() => {
    //         this.intersectionObserver();
    //       });
    //     }
    //   },
    mounted() {
      // 静态数据的写法
      this.$nextTick(() => {
        this.intersectionObserver();
      });      
    },
    created(){
        this.data1.push(this.data[0])
    },
    methods: {
      intersectionObserver() {
        this.io = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
              this.activeKey = parseInt(entry.target.dataset.index);
              this.$refs.sideBarRef.$el
                .querySelector(`.side-bar-item-${this.activeKey}`)
                .scrollIntoView({
                  block: 'end'
                });
            }
          });
        });
        const detailBlockElList = this.$refs.detailListRef.$el.querySelectorAll(
          '.detail-block'
        );
        detailBlockElList.forEach((el) => {
          this.io.observe(el);
        });
      },
   
      handleNavToggle() {
        this.navShow = !this.navShow;
        this.activeKey = 0;
      },
   
      handleChange(index) {
        this.data1 = []
        this.data1.push(this.data[index])
        // this.$refs.detailListRef.$el
        //   .querySelector(`.detail-block-${index}`)
        //   .scrollIntoView();
      }
    }
  };
  </script>
   
  <style scoped>
  .finance-morning-tea-detail-page {
    background-color: #fff;
    overflow: auto;
    /* height: 100vh; */
  }
   
  .detail-title-wrap {
    padding: 10px 15pxrem;
  }
   
  .detail-title {
    font-size: 18px;
    font-weight: 600;
    color: #253440;
    line-height: 22px;
  }
   
  .detail-date {
    font-size: 14px;
    color: #8695a1;
    margin-top: 4px;
  }
   
  .body-nav-btn {
    /* width: 0.46rem; */
    height: 0.4rem;
    box-shadow: 0rem 0rem 0.13rem 0rem rgba(0, 60, 195, 0.25);
    border-radius: 0rem 1rem 1rem 0rem;
    background-color: #2c85ff;
    background: #2c85ff url('../assets/images/set.png') 0.11rem center
      no-repeat;
    background-size: 0.2rem 0.2rem;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
  }
   
  .detail-body {
    display: flex;
    height: 85vh;
  }
   
  .body-side-bar {
    padding-top: 50px ;
     /* 0.54rem; */
    width:  290px;
    /* 0.92rem; */
    /* overflow-y: scroll; */
    background-color: #f7f9f9;
  }
   
  .body-content {
    padding: 0 0.1rem 0 0.13rem;
    flex: 1;
    /* overflow-y: scroll; */
  }
   
  .copyright-notice {
    margin: 0.16rem 0;
    font-size: 15px;
     /* 0.12rem; */
    color: #8695a1;
    line-height:20px;
     /* 0.17rem; */
  }
  </style>