<template>
    <div class="side-bar-wrap">
      <div class="body">
        <div
          :class="['side-bar-item', `side-bar-item-${i}`]"
          v-for="(item, i) in data"
          :key="i"
          @click="setIndex(i)"
        >
          <p :class="[index === i && 'active']">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </template>
   
  <script>
  export default {
    name: 'SideBar',
    model: {
      prop: 'activeKey'
    },
    props: {
      activeKey: {
        type: Number,
        default: 0
      },
      data: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        index: this.activeKey
      };
    },
    watch: {
      activeKey(val) {
        this.index = val;
      }
    },
    methods: {
      setIndex(index) {
        if (index !== this.index) {
          this.index = index;
          this.$emit('change', index);
        }
      }
    }
  };
  </script>
   
  <style scoped>
  .body {
    /* width: 0.92rem; */
    background: #f7f9f9;
    /* overflow-y: auto; */
    -webkit-overflow-scrolling: touch;
  }

  .side-bar-wrap {
     width: 100%;
  }
   
  .side-bar-item {
    min-height: 60px;
     /* 0.62rem; */
    border-bottom: 0.01rem #e8edf0 solid;
    display: flex;
    align-items: center;
  }
   
  .side-bar-item:last-child {
    border-bottom: none;
  }
   
  .side-bar-item p {
    /* width: 0.72rem; */
    padding: 0.08rem 0;
    margin-left: 10px;
    margin-top: 10px;
    /* 0.13rem; */
    font-size: 16px;
     /* 0.16rem; */
    font-weight: 600;
    color: #253440;
    line-height: 22px;
    /* 0.22rem; */
  }
   
  .side-bar-item p.active {
    color: #2c85ff;
    position: relative;
  }
   
  .side-bar-item p.active::before {
    content: '';
    display: block;
    opacity: 0.74;
    border-top: 0.06rem solid transparent;
    border-bottom: 0.06rem solid transparent;
    border-left: 0.05rem solid #0164ec;
    position: absolute;
    left: -0.13rem;
    top: 50%;
    transform: translateY(-50%);
  }
  </style>